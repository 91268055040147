.life {
  padding: 100px 200px 100px 150px;
}

.life p {
  margin: 35px 0;
  line-height: 26px;
}

@media only screen and (max-width: 800px) {
  .life {
    padding: 100px 100px;
  }
}

@media only screen and (max-width: 600px) {
  .life {
    padding: 100px 50px;
  }
}