.contribution-item {
  padding-left: 17px;
}

.contribution-item > p {
  position: relative;
} 
.contribution-item > p:nth-of-type(1):before {
  position: absolute;
  content: '';
  top: 14px;
  left: -11px;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background-color: black;
} 
