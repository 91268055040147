@font-face {
  font-family: 'book';
  src: url(../../assets/book.otf);
}

@font-face {
  font-family: 'skinny';
  src: url(../../assets/skinny.otf);
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  min-width: 320px;
  font-family: 'book', sans-serif;
  letter-spacing: .1em;
  background: #fafafa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

img {
  max-width: 100%;
}

#app > div {
  padding: 0 70px 0 50px;
  max-width: 1400px;
  margin: 0 auto;
}

@media only screen and (max-width: 900px) {
  #app > div {
    padding: 0 70px 0 20px;
  }
}

@media only screen and (max-width: 600px) {
  #app > div {
    padding: 0 20px;
  }
  .bullet {
    max-width: 100%;
  }
}

a {
  text-decoration: none;
  color: #111;
}
a.active-menu-item  {
  opacity: .5;
}

h2 {
  font-size: 22px;
}

p {
  margin: 10px 0;
  font-size: 20px;
  line-height: 1.5;
}

.page {
  margin-top: 70px;
}

ul, li {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
  font-size: 20px;
  line-height: 1.6;
}

.before:before, .after:after {
  position: absolute;
  content: '';
  width: 1px;
  background-color: #111;
}

.hide-menu-active {
  opacity: 1;
  transition: opacity .2s ease-in-out;
}
.menu-active .hide-menu-active {
  opacity: 0;
}