.contact {
  padding-top: 100px;
}

.contact.active {
  pointer-events: none;
}

.contact h1 {
  text-align: center;
}
