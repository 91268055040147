.page.cv {
  max-width: 1100px;
  margin: 70px auto;
}

.page.cv h2,
.page.cv h3,
.page.cv h4,
.page.cv p {
  margin: 0;
}

.page.cv p {
  font-size: 18px;
}

.cv .title {
  font-weight: 700;
}

.cv p {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.bullet {
  position: relative;
  max-width: 68%;
}
.bullet:before {
  position: absolute;
  content: '';
  top: 12px;
  top: 10px;
  left: 3px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #121212;
}

.tools {
  font-size: 16px;
  font-weight: bold;
}

.bullet, .tools {
  padding-left: 16px;
}

.cv p.subhead > span {
  margin-right: 7px;
}

.cv-header h1,
.cv-header h2,
.cv-header p  {
  margin: 0;
  font-weight: 300;
}

.page.cv h3,
.page.cv .bullet {
  font-size: 18px;
}

.cv-header {
  display: flex;
  justify-content: space-between;
  gap: 10%;
}

.cv-header h1 {
  font-size: 27px;
}

.page.cv .cv-header h2 {
  font-size: 19px;
  margin-top: 11px;
}

.cv-header p {
  font-size: 17px;
  color: black;
  letter-spacing: 0;
  line-height: 1.3;
  font-size: 19px;
  opacity: 1;
}

.cv-title {
  display: flex;
  flex-direction: column;
}

.page.cv .cv-header .cv-title p {
  margin-top: 27px;
}

.cv-contacts {
  flex: 0 0 217px;
  display: flex;
  flex-direction: column;
  gap: 7px;

}

.cv-contacts p {
  justify-content: space-between;
}