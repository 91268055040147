.main-menu {
  position: fixed;
  top: 10vh;
  right:0;
  height: 250px;
  width: 200px;
  opacity: 0;
  transition: all .3s ease;
  z-index: -1;
}
.main-menu.showing {
  width: 200px;
  opacity: 1;
  z-index: 99;
}

.main-menu ul {
  padding-top: 35px;
}

.main-menu li {
  position: relative;
  display: block;
  padding: 10px 0 10px 25px;
  font-size: 16px;
  line-height: 1.2;
  text-transform: uppercase;
  transition: .3s all ease;
}

.main-menu li {
  opacity: 0;
}
.main-menu.showing li {
  opacity: 1;
  padding-left: 30px;
}

.main-menu li:nth-of-type(1) {
  transition-delay: .08s;
}
.main-menu li:nth-of-type(2) {
  transition-delay: .1s;
}
.main-menu li:nth-of-type(3) {
  transition-delay: .07s;
}
.main-menu li:nth-of-type(4) {
  transition-delay: .12s;
}
.main-menu li:nth-of-type(5) {
  transition-delay: .15s;
}
.main-menu li:nth-of-type(6) {
  transition-delay: .18s;
}
