.code {
  position: relative;
  padding-top: 20px;
  max-width: 500px;
  margin: 0 auto;
}

.code nav {
  margin: 0 auto;
  max-width: 500px;
}

.code a {
  color: #08c;
  margin-right: 10px;
}

.code p {
  display: flex;
  align-items: center;
}

.code-content {
  width: 100%;
  height: 100%;
  padding-top: 70px;
  padding-bottom: 70px;
}

.code-tab-page {
  position: absolute;
  width: 100%;
  max-height: 0px;
  overflow: hidden;
  opacity: 0;
}
.code-tab-page.active {
  max-height: none;
  opacity: 1;
  transition: all .4s ease .1s;
  padding-bottom: 50px;
}

.code-tab-page li {
  position: relative;
  margin-left: 7px;
}

.code-tab-page li:before {
  position: absolute;
  content: '';
  top: 15px;
  left: -7px;
  width: 3px;
  height: 3px;
  background-color: black;
  border-radius: 50%;
}

@media only screen and (max-width: 900px) {
  .code-tab-page li, .code-tab-page p {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 900px) {
  .code ul.before:before, ul.after:after {
    width: 1px;
  }
  .code {
    width: 90%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 370px) {
  .code-tab-page {
    padding-right: 10px;
  }
}




