
.code-tab-nav {
  width: 100%;
  height: 50px;
  position: relative;
}

.code-tab-nav.before:before, .code-tab-nav.after:after {
  z-index: -2;
}
.code-tab-nav.before:before, .code-tab-nav.after:after {
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #a1a1a1;
}
.code-tab-nav.before:before {
  left: 0;
}
.code-tab-nav.after:after {
  right: 0;
}
.code-tab-nav.before:before, .code-tab-nav.after:after {
  transition: top .25s ease .35s;
}
.code-tab-nav.before.hide-border-left:before, .code-tab-nav.after.hide-border-right:after {
  transition: top .15s ease;
}
.code-tab-nav.before.hide-border-left:before {
  top: 50px;
}
.code-tab-nav.after.hide-border-right:after {
  top: 50px;
}

.code-tab-nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.code-tab-nav li {
  position: relative;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  line-height: 50px;
  cursor: pointer;
  color: #121212;
  transition: .3s color ease .22s;
}
.code-tab-nav li:hover {
  color: #fff;
}
.code-tab-nav li.before:before {
  width: 100%;
  height: 0;
  background-color: #121212;
  top: 0;
  left: 0;
  transition: background-color .2s ease .1s;
  transition: height .3s ease;
  z-index: -1;
}
.code-tab-nav li:hover.before:before {
  height: 100%;
}
.code-tab-nav li:before {
  transition: background-color .2s ease .1s;
}
.code-tab-nav li.tab-active.before:before, li.tab-active:hover.before:before {
  background: #a1a1a1;
  color: #fff;
  height: 100%;
  pointer-events: none;
  transition: background-color .4s ease-in-out .1s;
}
.code-tab-nav li.tab-active {
  color: #fff;
  pointer-events: none;
}
