.app-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 0 50px;
  background-color: #fafafa;
  z-index: 1;
}

.app-header ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app-header ul li {
  line-height: 50px;
}

.app-header ul li span {
  display: inline-block;
  position: relative;
}

.header-name {
  margin-right: 10px;
}

.header-current-page {
  position: relative;
  padding-left: 20px;
}
.header-current-page.before:before {
  height: 16px;
  width: 1px;
  top: 17px;
  left: 5px;
  transform: rotateZ(25deg);
  transition: .2s all ease;
}
.header-current-page.after:after {
  height: 1px;
  width: 0px;
  top: 40px;
  left: 9px;
  transition: .2s width ease;
}

.menu-active .header-current-page.after:after {
  width: 30px;
  height: 1px;
  width: 20px;
  top: 42px;
  left: 6px;
  transition: .2s width ease;
}
.menu-active .header-current-page.before:before {
  width: 30px;
  height: 16px;
  width: 1px;
  top: 27px;
  left: 5px;
  transform: rotateZ(0deg);
}

@media only screen and (min-width: 1400px) {
  .app-header ul {
    width: 1300px;
    margin: 0 auto;
    padding: 0;
  }
}

@media only screen and (max-width: 600px) {
  h2 {
    font-size: 25px;
  }

  .app-header {
    top: auto;
    bottom: 0;
    padding: 0;
    background-color: #121212;
    color: #fff;
  }
  
  .app-header ul {
    justify-content: space-around;
  }

  .app-header ul li {
    font-size: 14px;
  }

  .header-name {
    margin: 0;
  }

  .current-page.before:before {
    background-color: #fff;
  }
  /* don't animate route on phone */
  .header-current-page.menu-showing.before:before {
    top: 0px;
    left: 5px;
    transform: rotateZ(0deg);
  }
}

@media only screen and (max-width: 400px) {
  .header-current-page {
    display: none;
  }
}
