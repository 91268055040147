.home {
  position: relative;
  padding-top: 100px;
  margin-top: 70px;
  text-align: center;
}

.home-repo-link {
  position: fixed;
  left: calc(50% - 12.5px);
  bottom: 20px;
}

@media only screen and (max-width: 600px) {
  .home-repo-link {
    bottom: 70px;
  }
}

.home-repo-link .github {
  margin: 0;
}
